<template>
  <div>
    <b-alert v-if="error.length > 0" variant="danger" show>
      <div class="alert-heading text-center">
        <feather-icon :icon="'AlertTriangleIcon'" size="48" />
        <h4 class="mt-1">Error obteniendo datos</h4>
      </div>
      <div class="alert-body text-center">
        <p class="mb-1">
          Ocurrio un error al obtener Notificaciones Push [{{ error }}]
        </p>
        <b-button @click="reintentar" variant="outline-dark"
          >Reintentar</b-button
        >
      </div>
    </b-alert>

    <b-card v-else no-body>
      <b-card-header>
        <b-card-title>Notificaciones Push</b-card-title>
        <crear-editar-modal
          :push="selectedNotification"
          :callback="getNotificacionesPush"
        />
      </b-card-header>
      <b-card-body>
        <div class="custom-search d-flex justify-content-end">
          <b-form-group>
            <div class="d-flex align-items-center">
              <label class="mr-1">Buscar</label>
              <b-form-input
                type="text"
                class="d-inline-block"
                @input="onSearch"
                placeholder="Buscar"
              />
            </div>
          </b-form-group>
        </div>

        <!-- table -->
        <vue-good-table
          mode=""
          :rows="rows"
          :columns="columns"
          :totalRows="totalRecords"
          :isLoading.sync="loading"
          @on-page-change="onPageChange"
          @on-per-page-change="onPerPageChange"
          :sort-options="{
            enabled: false,
          }"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm,
          }"
          :pagination-options="{
            enabled: true,
            perPage: pageLength,
          }"
        >
          <template slot="emptystate">No hay registros para mostrar</template>

          <template slot="table-row" slot-scope="props">
            <!-- Column: Fecha -->
            <span v-if="props.column.field === 'programada'">
              <p class="mb-0">{{ formattedDate(props.row.programada) }}</p>
              <p class="mb-0" v-if="props.row.programada !== ''">
                {{ formattedHour(props.row.programada) }}
              </p>
            </span>
            <!-- Column: Estado -->
            <span v-else-if="props.column.field === 'estado'">
              <b-badge :variant="statusVariant(props.row.estado)">
                {{ statusText(props.row.estado) }}
              </b-badge>
            </span>

            <!-- Column: Action -->
            <span v-else-if="props.column.field === 'action'">
              <span v-if="showActions(props.row.programada)">
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item @click="edit($event, props.row)">
                    <feather-icon icon="Edit2Icon" class="mr-50" />
                    <span>Editar</span>
                  </b-dropdown-item>
                  <b-dropdown-item @click="confirmDelete($event, props.row)">
                    <feather-icon icon="TrashIcon" class="mr-50" />
                    <span>Eliminar</span>
                  </b-dropdown-item>
                </b-dropdown>
              </span>
              <span v-else> Sin Acciones </span>
            </span>

            <!-- Column: Otras Columnas -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap"> Cantidad de registros </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['10', '15', '20']"
                  class="mx-1"
                  @input="
                    (value) => props.perPageChanged({ currentPerPage: value })
                  "
                />
              </div>
              <div>
                <b-pagination
                  align="right"
                  class="mt-1 mb-0"
                  @input="(value) => props.pageChanged({ currentPage: value })"
                  :value="1"
                  :per-page="pageLength"
                  last-number
                  :total-rows="props.total"
                  first-number
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-card-body>

      <b-modal
        title="Error"
        ok-only
        v-model="showErrorModal"
        centered
        ok-title="Accept"
        ok-variant="danger"
        modal-class="modal-danger"
      >
        <b-card-text>
          {{ error }}
        </b-card-text>
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BAlert,
  BBadge,
  BButton,
  BAvatar,
  BSpinner,
  BDropdown,
  BCardText,
  BCardBody,
  BFormGroup,
  BFormInput,
  BCardTitle,
  BCardHeader,
  BFormSelect,
  BPagination,
  BDropdownItem,
} from "bootstrap-vue";
import moment from "moment";
import vSelect from "vue-select";
import { getAuthToken } from "@/auth/utils";
import { VueGoodTable } from "vue-good-table";
import CrearEditarModal from "./CrearEditar.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BAlert,
    BBadge,
    BButton,
    BAvatar,
    vSelect,
    BSpinner,
    BDropdown,
    BCardText,
    BCardBody,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCardHeader,
    BPagination,
    BFormSelect,
    VueGoodTable,
    BDropdownItem,
    CrearEditarModal,
    ToastificationContent,
  },
  data() {
    return {
      page: 1,
      rows: [],
      error: "",
      loading: true,
      searchTerm: "",
      pageLength: 10,
      totalRecords: 0,
      seachTimeout: null,
      showErrorModal: false,
      selectedNotification: {},
      columns: [
        {
          label: "Titulo",
          field: "titulo",
        },
        {
          label: "Mensaje",
          field: "mensaje",
        },
        {
          label: "Fecha Programada",
          field: "programada",
        },
        {
          label: "Acciones",
          field: "action",
        },
      ],
    };
  },
  mounted() {
    this.getNotificacionesPush();
  },
  computed: {
    statusText() {
      const status = {
        true: "Activo",
        false: "Inactivo",
      };
      return (s) => status[s];
    },
    statusVariant() {
      const statusColor = {
        false: "light-warning",
        true: "light-success",
      };
      return (s) => statusColor[s];
    },
  },
  methods: {
    showActions(programada) {
      if (programada !== "") {
        return moment().isBefore(moment(programada));
      }
      return false;
    },
    reintentar(event) {
      event && event.preventDefault();
      this.error = "";
      this.showErrorModal = false;
      this.getNotificacionesPush();
    },
    edit(event, notification) {
      event && event.preventDefault();
      this.selectedNotification = {};
      this.$nextTick(() => {
        this.selectedNotification = notification;
      });
    },
    confirmDelete(event, notification) {
      event && event.preventDefault();
      this.$bvModal
        .msgBoxConfirm("Desea eliminar la notificacion push?.", {
          size: "sm",
          title: "Confirmacion",
          okTitle: "Eliminar",
          centered: true,
          okVariant: "danger",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
        })
        .then((value) => {
          if (value) this.deletePush(notification);
        });
    },
    onChangeFilters() {
      this.getNotificacionesPush();
    },
    onSearch(query) {
      /*  this.loading = true; */
      this.searchTerm = query;
      /*  clearTimeout(this.seachTimeout);
      this.seachTimeout = setTimeout(() => {
        this.getNotificacionesPush();
      }, 2500); */
    },
    onPageChange(params) {
      this.page = params.currentPage;
      //this.getNotificacionesPush();
    },
    onPerPageChange(params) {
      this.pageLength = params.currentPerPage;
      //this.getNotificacionesPush();
    },
    formattedDate(originalDate) {
      if (originalDate !== "") return moment(new Date(originalDate)).format("DD/MM/YYYY");
      else return "Inmediata";
    },
    formattedHour(originalDate) {
      return moment(new Date(originalDate)).format("HH:mm a");
    },
    deletePush(notification) {
      this.loading = true;
      this.$http
        .post(this.$store.state.app.middlewareURL, {
          path: "/Customer/CrearPush",
          body: JSON.stringify({
            OTP: "D",
            IDPush: notification.idPush,
            tokenSesion: getAuthToken(),
          }),
        })
        .then((res) => {
          if (res.data.bOk) {
            this.getNotificacionesPush().then(() => {
              this.$toast({
                props: {
                  icon: "BellIcon",
                  text: `Se elimino correctamente la notificacion push`,
                  title: "Exito",
                },
                component: ToastificationContent,
              });
            });
          } else {
            throw new Error(res.data.mensaje);
          }
        })
        .catch((e) => {
          this.$toast({
            props: {
              icon: "AlertTriangleIcon",
              text: `Ocurrio un error al eliminar la notificacion push (${e.message}).`,
              title: "Error",
              variant: "danger",
            },
            component: ToastificationContent,
          });
        });
    },
    getNotificacionesPush() {
      this.loading = true;
      return new Promise((resolve, reject) => {
        this.$http
          .post(this.$store.state.app.middlewareURL, {
            path: "/Customer/ListarPush",
            body: JSON.stringify({
              OTP: "V",
              tokenSesion: getAuthToken(),
            }),
          })
          .then((res) => {
            this.loading = false;
            if (res.data.bOk) {
              this.rows = res.data.lsPushItems;
              //this.totalRecords = res.data[0].totalpaginas;
              resolve();
            } else {
              this.error = res.data.mensaje;
              this.showErrorModal = true;
              reject();
            }
          })
          .catch((e) => {
            this.loading = false;
            this.error = e.message;
            this.showErrorModal = true;
            reject();
          });
      });
    },
  },
};
</script>

<style lang="scss" >
table.vgt-table {
  font-size: 13px !important;
}
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
