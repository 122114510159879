<template>
  <div>
    <b-button @click="onclickCreate" variant="primary"> Crear </b-button>
    <b-modal
      id="modal-crear-push"
      size="lg"
      :title="titleModal"
      v-model="show"
      centered
      hide-footer
      no-close-on-backdrop
    >
      <div v-if="loading" class="loader">
        <b-spinner />
      </div>
      <form-wizard
        v-else
        color="#04a82c"
        class="wizard-vertical"
        layout="vertical"
        :title="null"
        :subtitle="null"
        @on-complete="formSubmitted"
        back-button-text="Volver"
        next-button-text="Siguiente"
        :finish-button-text="finishWizard"
      >
        <tab-content title="Objetivo">
          <h5>Objetivo</h5>
          <b-form-group>
            <b-form-radio-group
              id="v-tipo"
              buttons
              v-model="mPush.tipo"
              :options="tipos"
              button-variant="outline-primary"
            />
          </b-form-group>

          <b-form-group
            v-if="mPush.tipo === 'Bodegas'"
            label="Bodegas"
            label-for="v-bodegas"
          >
            <v-select
              id="v-bodegas"
              label="nombre"
              v-model="mPush.bodegas"
              multiple
              :reduce="(b) => b.id"
              :options="bodegas"
              :clearable="false"
            />
          </b-form-group>

          <b-form-group
            v-if="mPush.tipo === 'Sectores'"
            label="Sectores"
            label-for="v-sectores"
          >
            <v-select
              id="v-sectores"
              label="nombre"
              v-model="mPush.sectores"
              multiple
              :reduce="(s) => s.id"
              :options="sectores"
              :clearable="false"
            />
          </b-form-group>
        </tab-content>

        <tab-content title="Mensaje">
          <h5>Mensaje</h5>

          <b-form-group label="Titulo" label-for="v-titulo">
            <b-form-input
              id="v-titulo"
              v-model="mPush.titulo"
              placeholder="Titulo"
            />
          </b-form-group>

          <b-form-group label="Mensaje" label-for="v-mensaje">
            <b-form-textarea
              id="v-mensaje"
              rows="3"
              v-model="mPush.mensaje"
              placeholder=""
            />
          </b-form-group>
        </tab-content>

        <tab-content title="Programar">
          <b-row>
            <b-col cols="12">
              <h5>Programar envio del Push</h5>
            </b-col>

            <b-col cols="12">
              <b-form-group>
                <b-form-checkbox class="mt-1" v-model="mPush.programar" switch>
                  Programar
                </b-form-checkbox>
              </b-form-group>
            </b-col>

            <b-col cols="12" v-if="mPush.programar">
              <b-form-group
                label="Fecha programada"
                label-for="v-fecha-programada"
              >
                <b-form-datepicker
                  id="v-fecha-programada"
                  :min="new Date()"
                  local="es"
                  v-model="mPush.fechaProgramada"
                  placeholder="Seleccione la fecha programada"
                  :initial-date="new Date()"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" v-if="mPush.programar">
              <b-form-group
                label="Hora programada"
                label-for="v-hora-programada"
              >
                <b-form-timepicker
                  id="v-hora-programada"
                  locale="en"
                  v-model="mPush.horaProgramada"
                  placeholder="Seleccione la hora programada"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </tab-content>

        <tab-content title="Resumen">
          <h4 class="mb-2">Notificacion Push:</h4>

          <b-row>
            <b-col cols="12">
              <b-media no-body>
                <b-media-aside class="mb-1">
                  <b-avatar rounded variant="light-secondary" size="30">
                    <feather-icon icon="NavigationIcon" size="15" />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <small>Titulo</small>
                  <p class="mhj">{{ mPush.titulo }}</p>
                </b-media-body>
              </b-media>
            </b-col>
            <b-col cols="12">
              <b-media no-body>
                <b-media-aside class="mb-1">
                  <b-avatar rounded variant="light-secondary" size="30">
                    <feather-icon icon="FileTextIcon" size="15" />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <small>Mensaje</small>
                  <p class="mhj">{{ mPush.mensaje }}</p>
                </b-media-body>
              </b-media>
            </b-col>

            <b-col cols="12" v-if="mPush.programar">
              <b-media no-body>
                <b-media-aside class="mb-1">
                  <b-avatar rounded variant="light-secondary" size="30">
                    <feather-icon icon="CalendarIcon" size="15" />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <small>Fecha Programada</small>
                  <p class="mhj">{{ formattedDate }}</p>
                </b-media-body>
              </b-media>
            </b-col>

            <b-col cols="12" class="mb-1">
              <app-collapse accordion type="border">
                <app-collapse-item
                  v-if="mPush.tipo === '1'"
                  title="Bodegas"
                  class="store-cont"
                  isVisible
                >
                  <b-badge
                    pill
                    :key="bodega"
                    v-for="bodega in mPush.bodegas"
                    variant="light-dark"
                    >{{ getWarehouseName(bodega) }}</b-badge
                  >
                </app-collapse-item>
                <app-collapse-item
                  v-if="mPush.tipo === '2'"
                  title="Sectores"
                  class="store-cont"
                  isVisible
                >
                  <b-badge
                    pill
                    :key="sector"
                    v-for="sector in mPush.sectores"
                    variant="light-dark"
                    >{{ getSectorName(sector) }}</b-badge
                  >
                </app-collapse-item>
              </app-collapse>
            </b-col>
          </b-row>
        </tab-content>
      </form-wizard>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BBadge,
  BModal,
  BMedia,
  BAvatar,
  BButton,
  BSpinner,
  BMediaBody,
  BFormGroup,
  BFormInput,
  BMediaAside,
  BFormTextarea,
  BFormCheckbox,
  BFormTimepicker,
  BFormRadioGroup,
  BFormDatepicker,
  BFormCheckboxGroup,
} from "bootstrap-vue";
import moment from "moment";
import vSelect from "vue-select";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { FormWizard, TabContent } from "vue-form-wizard";
import { getAuthToken, getUserID } from "@/auth/utils";

export default {
  components: {
    BRow,
    BCol,
    BBadge,
    BModal,
    BMedia,
    BAvatar,
    BButton,
    vSelect,
    BSpinner,
    FormWizard,
    TabContent,
    BMediaBody,
    BFormGroup,
    BFormInput,
    AppCollapse,
    BMediaAside,
    BFormTextarea,
    BFormCheckbox,
    BFormTimepicker,
    AppCollapseItem,
    BFormRadioGroup,
    BFormDatepicker,
    BFormCheckboxGroup,
  },
  props: {
    push: {
      type: Object,
      required: true,
    },
    callback: {
      type: Function,
      required: true,
    },
  },
  watch: {
    push: function (val) {
      const newVal = JSON.parse(JSON.stringify(val));
      if (newVal.idPush) {
        this.show = true;
        this.create = false;
        if (newVal.idBodegas.length > 0) {
          newVal.tipo = "Bodegas";
          newVal.bodegas = newVal.idBodegas;
        }
        if (newVal.idSectores.length > 0) {
          newVal.tipo = "Sectores";
          newVal.sectores = newVal.idSectores;
        }
        if (newVal.programada.length > 0) {
          newVal.programar = true;
          const programada = moment(newVal.programada);
          newVal.horaProgramada = programada.format("HH:mm:ss");
          newVal.fechaProgramada = programada.format("YYYY-MM-DD");
        }
        this.mPush = newVal;
        if (this.sectores.length === 0) this.getSectorsWarehouses();
      }
    },
  },
  data() {
    return {
      show: false,
      mPush: {},
      tipos: ["Bodegas", "Sectores"],
      create: true,
      bodegas: [],
      loading: true,
      sectores: [],
    };
  },
  methods: {
    getSectorName(id) {
      return this.sectores.find((s) => s.id === id).nombre;
    },
    getWarehouseName(id) {
      return this.bodegas.find((b) => b.id === id).nombre;
    },
    onclickCreate(event) {
      event.preventDefault();
      this.show = true;
      this.mPush = {
        tipo: "Bodegas",
        idPush: 0,
        titulo: "",
        mensaje: "",
        bodegas: [],
        sectores: [],
        programar: false,
        horaProgramada: "",
        fechaProgramada: "",
      };
      this.create = true;
      if (this.sectores.length === 0) this.getSectorsWarehouses();
    },
    getSectorsWarehouses() {
      this.loading = true;
      return new Promise((resolve, reject) => {
        this.$http
          .post(this.$store.state.app.middlewareURL, {
            path: "/Administracion/SectoresBodegas",
            body: JSON.stringify({
              tokenSesion: getAuthToken(),
            }),
          })
          .then((res) => {
            this.loading = false;
            if (res.data.bOk) {
              this.bodegas = res.data.lsbodegas;
              this.sectores = res.data.lssectores;
              resolve();
            } else {
              reject(res.data.mensaje);
            }
          })
          .catch((e) => {
            this.loading = false;
            reject(e.message);
          });
      });
    },
    formSubmitted() {
      this.loading = true;
      this.$http
        .post(this.$store.state.app.middlewareURL, {
          path: "/Customer/CrearPush",
          body: JSON.stringify({
            otp: this.create ? "I" : "U",
            Tipo: this.mPush.tipo,
            IDPush: this.mPush.idPush,
            Titulo: this.mPush.titulo,
            Mensaje: this.mPush.mensaje,
            IDBodegas: this.mPush.bodegas,
            IDSectores: this.mPush.sectores,
            tokenSesion: getAuthToken(),
            UsuarioCreacion: `${getUserID()}`,
            FechaProgramada: this.mPush.programar
              ? moment(
                  `${this.mPush.fechaProgramada} ${this.mPush.horaProgramada}`
                ).format("YYYY-MM-DD HH:mm:ss.SSS")
              : undefined,
          }),
        })
        .then((res) => {
          if (res.data.bOk) {
            this.show = false;
            this.callback();
            this.$toast({
              props: {
                icon: "BellIcon",
                text: `Se ${
                  this.create ? "creo" : "actualizo"
                } correctamente la notificacion Push`,
                title: "Exito",
              },
              component: ToastificationContent,
            });
          } else {
            throw new Error(res.data.mensaje);
          }
        })
        .catch((e) => {
          this.$toast({
            props: {
              icon: "AlertTriangleIcon",
              text: `Ocurrio un error al ${
                this.create ? "crear" : "actualizar"
              } la notificacion Push (${e.message}).`,
              title: "Error",
              variant: "danger",
            },
            component: ToastificationContent,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  computed: {
    titleModal() {
      if (this.create) return "Crear Notificacion Push";
      else return "Editar Notificacion Push";
    },
    finishWizard() {
      if (this.create) return "Crear Notificacion Push";
      else "Editar Notificacion Push";
    },
    formattedDate() {
      return moment(
        `${this.mPush.fechaProgramada} ${this.mPush.horaProgramada}`
      ).format("DD/MM/YYYY hh:mm a");
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
.mhj {
  margin: 0;
  font-size: 12px;
  line-height: 8px;
  font-weight: bold;
}
.store-cont span {
  margin-right: 5px;
}
.loader {
  height: 20vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
